export default [
  {
    path: '/',
    name: 'app-home',
    component: () => import('@/views/app/map/MapPage.vue'),
  },
  {
    path: '/checkout/:orderKey',
    name: 'app-checkout',
    component: () => import('@/views/app/checkout/CheckoutPage.vue'),
  },
  {
    path: '/receipt/:orderKey',
    name: 'app-receipt',
    component: () => import('@/views/app/receipt/ReceiptPage.vue'),
  },
]
