import Vue from 'vue'
import moment from "moment-timezone";
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://cors.lk/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})


axiosIns.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const expired_at = JSON.parse(localStorage.getItem('expired_at'))
    if (token && expired_at && expired_at > moment().valueOf()) {
      config.headers.Authorization = `${token}`
    } else {
      // Do something... Usually logout user.
      console.log('session_expired');
    }
    return config
  },
  error => Promise.reject(error),
)

Vue.prototype.$http = axiosIns

export default axiosIns
